
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';
import NonLoggedInLayout from '@/components/NonLoggedInLayout.vue';

const namespace: string = 'LoginFail';

@Component({
  components: { BasicHelpDialog, NonLoggedInLayout }
})
export default class LoginComponent extends Vue {
  $refs!: {
    helpDeskDialog: HTMLFormElement;
  };
  // brokenRobotImg: any = require('@/assets/roboterror_large.gif');
  // footerOptions: any = [
  //   { Id: 1, Text: 'Templates', Readonly: false },
  //   { Id: 2, Text: 'Contact us', Readonly: false },
  //   { Id: 3, Text: 'Transparency', Readonly: false },
  //   { Id: 4, Text: 'Terms of Service', Readonly: false },
  //   { Id: 5, Text: '@ 2021 Just One Label, LLC', Readonly: true }
  // ];

  openHelpDesk() {
    this.$refs.helpDeskDialog.openDialog();
  }
  mounted() {}
}
